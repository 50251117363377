.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f7f7f7;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
}

.logo-img {
  width: 80px;
  height: 80px;
}

.sneaker-wrapper {
  display: flex;
  width: 100%;
  height: 45%;
  position: relative;
}

.sneaker-wrapper-sneaker-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sneaker-wrapper-gray-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.sneaker-img {
  position: absolute;
  width: 600px;
  height: 253px;
}

.sections-side-one-selected-desktop {
  width: 213px;
  height: 130px;
  background: white;
  cursor: pointer;
}

.sections-side-one-selected-desktop:hover {
  background: #f7f7f7;
}

.sections-side-one-selected-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sections-side-img {
  width: 185px;
  height: 80px;
}

.sections-only-name-desktop {
  margin: 3px;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  color: #9b9999;
  font-size: 20px;
}

.side-sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 850px;
  background: white;
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  height: 350px;
  border: 1px solid hsl(0, 0%, 93%);
  padding-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 60px;
  padding-top: 50px;
}

.side-sections-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
}

.side-sections-img {
  width: 213px;
  height: 93px;
  cursor: pointer;
  padding: 0px 10px;
  transition: transform 150ms ease-in;
}

.side-sections-img:hover {
  transform: scale(1.1);
}

.side-sections-name {
  text-align: center;
  text-transform: capitalize;
  color: #b5b5b5;
  margin: 2px;
  font-size: 15px;
}

.side-sections-name-selected {
  padding: 3px 45px;
  color: black;
  font-weight: bold;
  background: #ffffff !important;
  text-transform: uppercase;
  border-bottom: 3px solid #000000;
}

.sections-wrapper {
  display: none;
}

.section-wrapper {
  cursor: pointer;
  background: transparent;
  outline: none;
  border: none;
}

.modal-sections-name-selected {
  color: white !important;
  font-weight: bold;
  background: #b3b3b3 !important;
  border-radius: 6px;
}

.sections-img {
  width: 250px;
  height: 110px;
  cursor: pointer;
  transition: transform 150ms ease-in;
}

.sections-img:hover {
  transform: scale(1.1);
}

.sections-name {
  text-align: center;
  text-transform: capitalize;
  color: #b3b3b3;
  margin: 1px 8px 3px 8px;
}

.sections-and-color-container {
  width: 830px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  background: #eeeeee;
  margin-top: 20px;
}

.sections-side-one-selected-desktop {
  width: 230px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: white;
}

.sections-side-icon {
  font-size: 35px;
  color: #9b9999;
}

.colors-wrapper-img-circle {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.colors-img {
  width: 40px;
  border-radius: 50%;
  border: 1px solid lightgray;
  cursor: pointer;
  margin: 3px;
}

.colors-img:hover {
  transform: scale(2);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%), 0 2px 9px 0 rgb(0 0 0 / 19%);
}

.rec .rec-carousel {
  width: 100%;
}

.cNNJIt {
  opacity: 0.3;
}

.rec.rec-arrow {
  background-color: transparent;
}

.rec.rec-arrow:hover {
  background-color: grey !important;
}

.rec.rec-arrow:focus {
  background-color: grey !important;
}

.modal-close {
  display: none;
}

.side-sections-close {
  display: none;
}

/* ------- SCREEN 768px ------- */
/* ------- SCREEN 768px ------- */
@media screen and (max-width: 850px) {
  .App {
    background: white;
  }

  .logo-wrapper {
    background: #f7f7f7;
  }

  .logo-img {
    margin-top: 20px;
  }

  .sneaker-wrapper {
    height: 100%;
    background: #f7f7f7;
  }

  .sneaker-img {
    width: 580px;
    height: 244px;
  }

  .sneaker-wrapper-gray-sections {
    display: none;
  }

  .sneaker-wrapper-sneaker-background {
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .sections-side-one-selected-desktop {
    display: none;
  }

  .sections-wrapper {
    height: 10%;
    width: 100%;
    display: flex;
  }

  .sections-one-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #b3b3b3;
    width: 100%;
    height: 100%;
  }

  .sections-only-name {
    text-align: center;
    text-transform: capitalize;
    padding: 11px 40px;
    border-radius: 4px;
    font-size: 20px;
  }

  .sections-icon {
    font-size: 30px;
    margin-right: 10px;
  }

  button:focus {
    outline: none;
    box-shadow: none;
    background-color: none;
  }

  .sections-img {
    width: 190px;
    height: 85px;
  }

  .sections-name {
    padding: 1px;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .sections-and-color-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 35%;
    background: #ffffff;
    border: none;
    margin-bottom: 30px;
  }

  .colors-img {
    width: 48px;
  }

  .modal-container {
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
  }

  .modal-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background: white;
    padding-bottom: 40px;
  }

  .modal-wrapper-icon-close {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-top: 1px solid #b8b8b8;
    border-bottom: none;
  }

  .modal-icon-close {
    color: rgb(174, 174, 174);
    font-size: 26px;
    margin: 10px;
  }

  .rec.rec-slider-container {
    margin: 0px;
  }

  .rec.rec-arrow {
    background-color: transparent;
    border-radius: 0px;
    min-width: 20px;
    width: 25px;
    height: 90px;
    box-shadow: none !important;
  }
}

@media screen and (max-width: 540px) {
  .sneaker-img {
    width: 529px;
    height: 224px;
  }
}

/* ------- SCREEN 450px ------- */
/* ------- SCREEN 450px ------- */
@media screen and (max-width: 450px) {
  .App {
    justify-content: start;
  }

  .logo-wrapper {
    display: flex;
    height: 15%;
  }

  .logo-img {
    margin-top: 5px;
    width: 70px;
    height: 70px;
  }

  .sneaker-wrapper {
    width: 100%;
    height: 35%;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .sneaker-img {
    width: 320px;
    height: 141px;
  }

  .sections-wrapper {
    height: 7%;
    width: 100%;
  }

  .sections-img {
    width: 140px;
    height: 70px;
  }

  .sections-one-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #b3b3b3;
    height: 100%;
    width: 100%;
  }

  .sections-icon {
    font-size: 30px;
    margin-right: 10px;
  }

  .sections-only-name {
    text-align: center;
    text-transform: capitalize;
    padding: 11px 40px;
    border-radius: 4px;
    font-size: 20px;
  }

  .modal-wrapper {
    padding-bottom: 0px;
  }

  .sections-and-color-container {
    width: 100%;
    height: 43%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    margin: 0px;
    background: white;
    border: none;
  }

  .colors-img {
    width: 41px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 8px;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 320px) {
  .colors-img {
    margin-top: 5px;
    width: 38px;
  }
}

@media screen and (max-width: 280px) {
  .sneaker-img {
    width: 274px;
    height: 126px;
  }

  .sections-img {
    width: 120px;
    height: 59px;
  }

  .colors-img {
    width: 36px;
  }
}

@media screen and (max-width: 830px) and (orientation: landscape) {
  .App {
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logo-wrapper {
    display: none;
  }

  .sneaker-wrapper {
    width: 100%;
    height: 47%;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    background: #f7f7f7;
  }

  .sneaker-img {
    width: 360px;
    height: 151px;
  }

  .sections-wrapper {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sections-one-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #d1d1d1;
    height: 100%;
    width: 100%;
  }

  .sections-icon {
    font-size: 30px;
    margin-right: 10px;
  }

  .sections-only-name {
    text-align: center;
    text-transform: capitalize;
    padding: 11px 40px;
    border-radius: 4px;
    font-size: 18px;
  }

  .sections-img {
    width: 180px;
    height: 79px;
  }

  .sections-name {
    padding: 3px;
  }

  .sections-and-color-container {
    display: flex;
    flex-wrap: wrap;
    background: white;
    width: 434px;
    margin-top: 0;
    border: none;
  }

  .sections-side-one-selected-desktop {
    display: none;
  }

  .colors-img {
    width: 38px;
    margin: 3px;
  }

  .modal-close {
    display: none;
  }

  .side-sections-close {
    display: none;
  }

  .modal-container {
    background: white;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
  }

  .modal-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #f7f7f7;
  }

  .modal-background {
    display: contents;
    justify-content: center;
    align-items: center;
  }

  .modal-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    height: 100%;
    padding-bottom: 0;
  }

  .modal-wrapper-icon-close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  .modal-icon-close {
    display: none;
  }
}

@media screen and (max-width: 640px) and (orientation: landscape) {
  .sections-img {
    width: 160px;
    height: 70px;
  }
}